<template>
  <div class="page">
    <van-row>
      <van-col span="3" @click="back()"
        ><div class="back-btn"><van-icon name="arrow-left" />返回</div></van-col
      >
      <van-col span="21">
        <van-search
          v-model="search.keyword"
          placeholder="请输入搜索关键词"
          ref="keyword"
          show-action
        >
          <template #action>
            <div @click="searchIssue">搜索</div>
          </template></van-search
        >
      </van-col>
    </van-row>
    <van-row class="title">
      <van-col span="24"><van-icon name="notes-o" /> 问题列表</van-col>
    </van-row>
    <van-list
      :finished="finishedShow"
      finished-text="没有更多了"
      offset="0"
      class="items"
      :immediate-check="false"
      @load="loadIssueList"
    >
      <div
        class="item"
        v-for="issue in issueList"
        :key="issue.code"
        @click="read(issue.code)"
      >
        <van-row>
          <van-col span="24" class="name text-short">{{ issue.name }}</van-col>
        </van-row>
        <van-row>
          <van-col span="18" class="score"
            >评分：<van-rate v-model="score" color="#ffd21e" size="16" readonly
          /></van-col>
          <van-col span="6" class="read">阅读：{{ issue.read }}次</van-col>
        </van-row>
      </div>
    </van-list>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Search, Icon, Rate, List } from 'vant'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Loading: Loading,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Rate.name]: Rate,
    [List.name]: List
  },
  data () {
    return {
      loadingShow: false,
      finishedShow: false,
      page: { current: 0, size: 10, last: 0 },
      search: { keyword: '' },
      issueList: [],
      score: 100
    }
  },
  mounted () {
    document.title = '常见问题'
    this.retrieveIssueList()
  },
  methods: {
    searchIssue () {
      this.issueList = []
      this.page.current = 0
      this.retrieveIssueList()
    },
    loadIssueList () {
      if (this.page.last >= this.page.current) {
        this.retrieveIssueList()
      } else {
        this.finishedShow = true
      }
    },
    async retrieveIssueList () {
      this.loadingShow = true
      this.page.current = this.page.current + 1
      var pd = { keyword: this.search.keyword, current: this.page.current, size: this.page.size }
      var { data: res } = await this.$http.post(this.BMS_URL + '/help/issue/retrieveReadIssueList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.issueList = this.issueList.concat(res.data)
        this.page.last = res.page.last
      }
      this.loadingShow = false
    },
    read (code) {
      this.$router.push({ path: '/help/issueRead', query: { code: code } })
    },
    back () {
      this.$router.push({ path: '/integrate/home' })
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  .back-btn {
    font-size: 14px;
    height: 50px;
    line-height: 50px;
  }
  .title {
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 14px;
    font-weight: 800;
    padding-left: 10px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
  }
  .items {
    .item {
      padding: 5px 20px;
      border-bottom: 1px solid #eee;
      .name {
        font-size: 14px;
        font-weight: 800;
        line-height: 30px;
        text-align: left;
      }
      .score {
        text-align: left;
        line-height: 25px;
        color: #666;
      }
      .read {
        text-align: left;
        line-height: 25px;
        color: #666;
      }
      .desc {
        text-align: left;
        line-height: 25px;
        color: #666;
      }
    }
  }
}
</style>
